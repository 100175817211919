@import './linear-progress.scss';

.loading-bar {
  height: 0.28rem;
  border-radius: 2px;
  background-clip: padding-box;
  transition: opacity 1.5s;
  display: none;
  width: 100%;
  overflow: hidden;
  margin-bottom: 1px;
  z-index: 1;
  position: absolute;
  top: 0;
}

.loading-bar-show {
  display: block;
  z-index: 14;
}
