$nav-height: 3.75rem;

body {
  overflow: hidden;
  margin: 0;

  .app-layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    #app-header-row {
      #app-header-container {
        position: static;
      }
    }

    #app-navigation-body-row {
      height: 100%;
      overflow: auto;

      #app-new-navigation {
        flex: 0 0 auto;
        height: 100%;
        position: static;
      }

      #app-body-row {
        flex: 1;
        overflow: auto;

        .app-body-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;

          #app-body {
            flex: 1;
          }
        }
      }
    }
  }
}

.hide {
  display: none;
}

.devtools-trigger {
  position: fixed;
  background: #007bff;
  padding: 8px;
  border: 1px solid transparent;
  border-color: #007bff;
  border-bottom: none;
  font-weight: bold;
  color: white !important;
  display: block;
  white-space: nowrap;
  text-decoration: none !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px 5px 0 0;
  left: 5%;
  bottom: 0;
  cursor: pointer;
}

.devtools-trigger:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

.devtools-content {
  display: none;
  position: absolute;
  background-color: #fff;
  bottom: 50px;
  left: 50px;
  max-width: 250px;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.show-devtools {
  display: block !important;
}
