@import '@catalystone/ui-core-assets/design-system/colors/color-palette';

@-webkit-keyframes primary-indeterminate-translate {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(83.67142%);
    transform: translateX(83.67142%);
  }

  100% {
    -webkit-transform: translateX(200.61106%);
    transform: translateX(200.61106%);
  }
}

@keyframes primary-indeterminate-translate {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(83.67142%);
    transform: translateX(83.67142%);
  }

  100% {
    -webkit-transform: translateX(200.61106%);
    transform: translateX(200.61106%);
  }
}

@-webkit-keyframes primary-indeterminate-scale {
  0% {
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }

  36.65% {
    -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }

  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    -webkit-transform: scaleX(0.66148);
    transform: scaleX(0.66148);
  }

  100% {
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }
}

@keyframes primary-indeterminate-scale {
  0% {
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }

  36.65% {
    -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }

  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    -webkit-transform: scaleX(0.66148);
    transform: scaleX(0.66148);
  }

  100% {
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }
}

@-webkit-keyframes secondary-indeterminate-translate {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(37.65191%);
    transform: translateX(37.65191%);
  }

  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(84.38617%);
    transform: translateX(84.38617%);
  }

  100% {
    -webkit-transform: translateX(160.27778%);
    transform: translateX(160.27778%);
  }
}

@keyframes secondary-indeterminate-translate {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(37.65191%);
    transform: translateX(37.65191%);
  }

  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(84.38617%);
    transform: translateX(84.38617%);
  }

  100% {
    -webkit-transform: translateX(160.27778%);
    transform: translateX(160.27778%);
  }
}

@-webkit-keyframes secondary-indeterminate-scale {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }

  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    -webkit-transform: scaleX(0.4571);
    transform: scaleX(0.4571);
  }

  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    -webkit-transform: scaleX(0.72796);
    transform: scaleX(0.72796);
  }

  100% {
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }
}

@keyframes secondary-indeterminate-scale {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }

  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    -webkit-transform: scaleX(0.4571);
    transform: scaleX(0.4571);
  }

  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    -webkit-transform: scaleX(0.72796);
    transform: scaleX(0.72796);
  }

  100% {
    -webkit-transform: scaleX(0.08);
    transform: scaleX(0.08);
  }
}

@-webkit-keyframes buffering {
  to {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}

@keyframes buffering {
  to {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}

.cx-linear-progress {
  position: relative;
  width: 100%;
  height: 4px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  overflow: hidden;
}

.cx-linear-progress__bar {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: none;
  animation: none;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition:
    transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1),
    -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.cx-linear-progress__bar-inner {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: none;
  animation: none;
  background-color: $button-teal;
}

.cx-linear-progress__buffering-dots {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: buffering 250ms infinite linear;
  animation: buffering 250ms infinite linear;
  background-repeat: repeat-x;
  background-size: 10px 4px;
}

.cx-linear-progress__buffer {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition:
    transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1),
    -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  background-color: var(--cx-global-bgcolor);
}

.cx-linear-progress__primary-bar {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.cx-linear-progress__secondary-bar {
  visibility: hidden;
}

.cx-linear-progress--indeterminate .cx-linear-progress__bar {
  transition: none;
}

.cx-linear-progress--indeterminate .cx-linear-progress__primary-bar {
  left: -145.166611%;
  -webkit-animation: primary-indeterminate-translate 2s infinite linear;
  animation: primary-indeterminate-translate 2s infinite linear;
}

.cx-linear-progress--indeterminate .cx-linear-progress__primary-bar > .cx-linear-progress__bar-inner {
  -webkit-animation: primary-indeterminate-scale 2s infinite linear;
  animation: primary-indeterminate-scale 2s infinite linear;
}

.cx-linear-progress--indeterminate .cx-linear-progress__secondary-bar {
  left: -54.888891%;
  -webkit-animation: secondary-indeterminate-translate 2s infinite linear;
  animation: secondary-indeterminate-translate 2s infinite linear;
  visibility: visible;
}

.cx-linear-progress--indeterminate .cx-linear-progress__secondary-bar > .cx-linear-progress__bar-inner {
  -webkit-animation: secondary-indeterminate-scale 2s infinite linear;
  animation: secondary-indeterminate-scale 2s infinite linear;
}

.cx-linear-progress--closed {
  opacity: 0;
}
