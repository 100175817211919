@import '@catalystone/ui-core-assets/design-system/appearance/shadow-utility';
@import '@catalystone/ui-core-assets/design-system/colors/color-palette';

#cx-service-down-card-wrapper {
  overflow: auto;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: $bg-body;

  &.show {
    display: flex !important;

    .cx-service-down-card,
    .cx-logo-wrapper {
      max-width: 440px;
    }

    .cx-service-down-card {
      background-color: $plain-white;
      border-radius: 0.5rem;
      padding: 1.5rem;

      @include card-shadow;

      &-note {
        font-size: 0.85rem;
        color: $wcag-grey;
        margin-top: 1rem;
      }
    }

    .cx-logo-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-top: 0.5rem;
    }
  }
}
